import React from 'react';
import './NewArticleBox.css'
import {useDispatch, useSelector} from "react-redux";
import {  useLocation } from "react-router-dom";
import ModalHeader from "./ModalHeader";
import ModalContentAudit from "./ModalContentAudit";
import ModalHowItWorks from "./ModalHowItWorks";
import SerpQuestions from './SerpQuestions';
import {
    getMissingPagesCount, getPagesManyKeywordsCount,
    getSuboptimalContentCount,
    getWrongIntentKeywordCount,
    getLowRankPageCount
} from "../../reducers/contentAuditIssues";
import {useState} from 'react'
import PreviousArticle from './PreviousArticle';
import { dashboardData } from '../../reducers/dashboard'
import {getIsContentWriterAuthor, getUserAccessToSuggestedTopics, getUserCanEdit} from "../../reducers/user";
import ModalStartArticle from "./ModalStartArticle";
import SpellbindingSuggestions from "./SpellbindingSuggestions";
import ProcessingModal from "./ProcessingModal";

const NewArticleBox = ({noArticles, close}) => {
    const [showImportMessage, setImportMessageDisplay] = useState(false)
    const [processingTopic, setProcessingTopic] = useState('')
    const [isSuggestedTopic, setIsSuggestedTopic] = useState(true)
    const missingPagesCount = useSelector(getMissingPagesCount);
    const wrongIntentKeywordCount = useSelector(getWrongIntentKeywordCount)
    // const suboptimalContentCount = useSelector(getSuboptimalContentCount)
    const pagesManyKeywordsCount = useSelector(getPagesManyKeywordsCount);
    const lowRankPageCount = useSelector(getLowRankPageCount)
    const dashboard = useSelector(dashboardData)
    const isContentWriterAuthor = useSelector(getIsContentWriterAuthor);
    const userCanEdit = useSelector(getUserCanEdit);
    const suggestedTopicsAccess = useSelector(getUserAccessToSuggestedTopics);
    let location = useLocation();
    const showSerpQuestionsSection = () => {
        return (missingPagesCount + wrongIntentKeywordCount + pagesManyKeywordsCount + lowRankPageCount) < 50
    }
    const noArticlesView = () => !isContentWriterAuthor && noArticles;
    const allContentIssuesLoaded = (missingPagesCount !== null) && (wrongIntentKeywordCount !== null) && (pagesManyKeywordsCount !== null) && (lowRankPageCount !== null)
    const processingManuallyTopic = (event) => {
        setProcessingTopic(event);
        setIsSuggestedTopic(false);
    }

    const processingSuggestedTopic = (event) => {
        setProcessingTopic(event)
        setIsSuggestedTopic(true);
    }
    return (
        <>
            {!!processingTopic && (
                <ProcessingModal
                    processingTopic={processingTopic}
                    isSuggested={isSuggestedTopic}
                />
            )}
            <div className="new-article-modal">
                <ModalHeader noArticles={noArticles} close={close} />
                <div className={`new-article-modal__content ${noArticlesView() ? 'new-article-modal__content--new-user' : ''}`}>
                    <div className="modal__content--left">
                        <ModalStartArticle
                            userCanEdit={userCanEdit}
                            setProcessingTopic={processingManuallyTopic}
                        />
                        {suggestedTopicsAccess && (
                            <SpellbindingSuggestions
                                setProcessingTopic={processingSuggestedTopic}
                            />
                        ) || (
                            <ModalContentAudit
                                showImportMessage={showImportMessage}
                                setImportMessageDisplay={setImportMessageDisplay}
                            />
                        )}
                    </div>
                    {noArticlesView() && (
                        <ModalHowItWorks />
                    )}
                </div>
                {showSerpQuestionsSection() && (
                    <SerpQuestions
                        allContentIssuesLoaded={allContentIssuesLoaded}
                        showImportMessage={showImportMessage}
                        setImportMessageDisplay={setImportMessageDisplay}
                    />
                )}
                {/*{((dashboard && dashboard.length  && !dashboard[0].empty)  && location.pathname === '/dashboard') &&*/}
                {/*    <PreviousArticle dashboard={dashboard}/>*/}
                {/*}*/}
            </div>
        </>
    );
};

export default NewArticleBox