import { Image } from '@tiptap/extension-image';

export const CustomImage = Image.extend({
  name: 'customImage',

  addAttributes() {
    // Get the default image attributes from the parent
    const parentAttributes = this.parent?.() || {
      src: {
        default: null,
        parseHTML: element => element.getAttribute('src'),
        renderHTML: attributes => {
          if (!attributes.src) {
            return {};
          }
          return { src: attributes.src };
        },
      },
      alt: {
        default: null,
        parseHTML: element => element.getAttribute('alt'),
        renderHTML: attributes => {
          if (!attributes.alt) {
            return {};
          }
          return { alt: attributes.alt };
        },
      },
      title: {
        default: null,
        parseHTML: element => element.getAttribute('title'),
        renderHTML: attributes => {
          if (!attributes.title) {
            return {};
          }
          return { title: attributes.title };
        },
      },
    };

    return {
      ...parentAttributes,
      dataAttributes: {
        default: {},
        parseHTML: element => {
          const attrs = {};
          Array.from(element.attributes).forEach(attr => {
            if (attr.name.startsWith('data-')) {
              attrs[attr.name] = attr.value;
            }
          });
          return attrs;
        },
        renderHTML: attributes => {
          if (!attributes.dataAttributes) {
            return {};
          }
          return attributes.dataAttributes;
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { dataAttributes, ...rest } = HTMLAttributes;
    return ['img', { ...rest, ...dataAttributes }];
  },
});